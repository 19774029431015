.partnership-action {
    font-weight: 700;
    font-size: 1.25rem;
    text-transform: capitalize;
    text-align: center;
}

.partnership-info {
    font-size: 1rem;
    line-height: 150%;
    text-align: center;
}

.partnership-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    width: 345px;
    height: 367px;

    @media (min-width: 768px) {
        border-right: 1px solid #ffffff;
    }
}

.partnership-card.variation-3 {
    border: none
}

.partnership-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}

.partnership-icon-container.variation-1 {
    background: #4217ba;
}

.partnership-icon-container.variation-2 {
    background: #ffd9d9;
}

.partnership-icon-container.variation-3 {
    background: #cba3ff;
}

.partnership-icon-container .cta-icon {
    height: 3.5rem;
    width: 3.5rem;
}

.volunteer-icon {
    filter: drop-shadow(2px 2px 2px #0c034e);
}

.partnership-icon {
    filter: drop-shadow(2px 2px 2px #6b5252);
}

.chapter-icon {
    filter: drop-shadow(2px 2px 2px #654292);
}


