@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
}

.App {
    width: 100%;
}

html {
    background: #130724;
    font-size: 16px;
    overflow-x: hidden;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    background: #130724;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font: 500 2rem "Lexend", sans-serif;
    @media (min-width: 768px) { /* Tablet and Desktop */
        font: 500 3rem "Lexend", sans-serif;
    }
}

h2 {
    font: 600 1.5rem "Lexend", sans-serif;
    @media (min-width: 768px) { /* Tablet and Desktop */
        font-size: 2.3rem;
    }
}

h3 {
    font: 500 1.125rem "Lexend", sans-serif;
    @media (min-width: 768px) { /* Tablet and Desktop */
        font-size: 2rem;
    }
}

h4 {
    font: 500 0.875rem "Lexend", sans-serif;
    @media (min-width: 768px) { /* Tablet and Desktop */
        font-size: 1.5rem;
    }
}

p {
    font: 500 1rem "Nunito Sans", sans-serif;
}

a {
    color: #ffffff;
    font: 400 1rem "Nunito Sans", sans-serif;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.subheading {
    font: 700 1.25rem "Lexend", sans-serif;
}

.caption {
    font: 700 1rem "Nunito Sans", sans-serif;
    letter-spacing: 0.281rem;
    text-transform: uppercase;
    background: linear-gradient(90deg, #ffd9d9 0%, #cba3ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* Animation */
.hidden {
    filter: blur(5px);
    opacity: 0;
    transition: opacity 1s 0.3s ease;
}

.show {
    filter: blur(0);
    opacity: 1;
}