.testimonial-container {
    background: #380974;
    border-radius: 20px;
    color: #ffffff;
    line-height: 150%;
    width: 100%;
    height: fit-content;
    padding: 2rem;
    text-align: left;
}

.message-container {
    margin-bottom: 1rem;
    @media (min-width: 768px) {
        height: 192px;
        overflow-y: auto;
    }
}

.message-container::-webkit-scrollbar {
    display: none;
  }

.profile-container {
    display: flex;
    column-gap: 1rem;
    text-align: left;
}

.profile-container img {
    height: 100%;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (min-width: 768px) {
    .testimonial-container {
        width: 310px;
        height: 310px;
    }
}