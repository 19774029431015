.nav {
    align-items: center;
    /* background-color: rgb(0, 0, 0); */
    display: flex;
    justify-content: space-between;
    padding: 2rem 3rem 2rem 5rem;
    position: absolute;
    width: 100%;
    height: 6rem;
    left: 0;
    z-index: 1;
}

.nav a {
    font-size: 1.15rem; 
    font-weight: 600;
    color: #FFFFFF;
    transition: color 0.2s; 
}

.nav a:hover {
    text-decoration: none;
    color: #817e85; 
}

.nav div {
    align-items: center;
    display: flex;
    column-gap: 2rem;
    height: 100%;
}

.logo {
    height: 3rem;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
   .nav {
    padding: 1rem 1rem;
   }
}

@media screen and (max-width: 450px) {
    .nav button {
        visibility: hidden;
    }
}