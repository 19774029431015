/* Site Stylesheet
  1. Global Styles
  2. Section Styles
  3. Media Queries
======================================== */


/* 1. Global Styles
======================================== */
.home-container {
    color: #ffffff;
    margin: 0 auto;
    width: 75%;
}

.section {
    min-height: 100dvh;
}

.center {
    text-align: center;
}

.title {
    margin: 2rem 0;
}

.two-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.two-column .column-1,
.two-column .column-2 {
    width: auto;
}

.three-column {
    display: block;
    column-gap: 15px;
}

.icon {
    width: 2rem;
    height: 2rem;
}


/* 2. Section Styles
======================================== */
.background {
    background-image: url('/src/assets/hero-image.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100dvh;
    width: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    position: relative;
}

.hero-cta {
    display: flex;
    column-gap: 1rem;
}

.hero-image {
    margin: 0;
}

.credibility { /*  Credibility Section  */
    display: flex;
    flex-direction: column;
    padding: 100px 0;
    row-gap: 4rem;
}

.column-1 img {
    width: 100%;
}

.partnered-orgs, 
.testimonials,
.testimonial-text { 
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
}

.featured-title { 
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
}

.banners {
    padding: 4rem 2.8rem 4rem 0rem;
    display: flex;
    background: linear-gradient(to right, #320b66, #420e87da, #72158ef9);
    border-radius: 1.25rem;
}

.banners button {
    background: white;
    border-radius: 1rem;
    color: black;
    border: none;
}

#banners {
    min-height: 30dvh;
    margin-top: 3rem;
    margin-bottom: 2rem;
}


.banners-text {
    text-align: left;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

@keyframes carousel {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

.orgs {
    position: relative;
    background-color: #380974;
    width: min(100%, 1065px);
    border-radius: 1.25rem;    
    padding: 2rem;
    overflow: hidden;
    white-space: nowrap;
}

.orgs::before {
    position: absolute;
    content: '';
    background: linear-gradient(to left, transparent, #380974);
    top: 0;
    left: 0;
    height: 100%;
    width: 10rem;
    z-index: 2;
}

.orgs::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, transparent, #340a59e7);
    top: 0;
    right: 0;
    height: 100%;
    width: 10rem;
    z-index: 2;
}

.orgs-carousel {
    display: inline-block;
    animation: 30s carousel infinite linear;
}

.orgs-logo {
    height: 4rem;
    margin: 0 3rem;
}

.testimonial-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: min(100%, 1065px);
    gap: 2rem
}

#about {
    padding: 3rem 0;
}

.about-text {  /*  About Us Section  */
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
}

.about-details {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.about-details-content { 
    display: flex;
    gap: 1rem;
}

.detailed-about-section {
    padding: 3rem 0;
}

.detailed-about-section > p { /*  Detailed About Us Section  */
   margin-bottom: 2rem;
}

.detailed-about-column.text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detailed-about-point {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
}

.detailed-about-image {
    width: 100%;
}

.collaboration, 
.questions {  /*  Collaboration & FAQs Section  */
    padding: 6.25rem 0;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.partnership-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* 3. Media Queries
======================================== */
@media screen and (max-width: 900px) {
    .home-container {
        width: 100%;
        padding: 0 1rem;
    }
}

@media screen and (min-width: 768px) { /*for tablets, laptops and wider*/
    .three-column {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
    }

    .two-column {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
    }

    .two-column .column-1,
    .two-column .column-2 {
        width: 100%;
    }

    .about-text {
        align-items: start;
        row-gap: 2rem;
    }

    .testimonial-cards {
        flex-direction: row;
    }

    .partnership-container {
        flex-direction: row;
    }
}

@media screen and (max-width: 767px) {
    .detailed-about-image {
        height: 450px;
        width: auto;
    }
    .banners {
        row-gap: 2rem;
        padding: 2rem;
    }
    .featured-title {
        text-align: center;
    }
}

@media screen and (max-width: 450px) {
    .hero-cta {
        flex-direction: column;
        row-gap: 1rem;
    }
    .banners {
        row-gap: 2rem;
        padding: 2rem;
    }
    .featured-title {
        text-align: center;
    }
    .detailed-about-image {
        height: auto;
        width: 100%;
    }
}